import OrganizationModel, { EnvironmentType } from "./OrganizationModel"
import IntegrationType from "./OrganizationModel"
import CcaBundle from './OrganizationModel'
export default class OrganizationGridViewModel {
    constructor(
        public Id?: string,
        public Name?: string,
        public Description?: string,
        public LearningPlatform?: string,
        public IsActive?: boolean,
        public IsExternal?: boolean,
        public LearningHostUrl?: string,
        public IntegrationType?: IntegrationType,
        public CreatedDate?: string,
        public Environment?: EnvironmentType,
        public SalesforceId?: string,
    ) { }
    
    public static Map(source: OrganizationModel) : OrganizationGridViewModel {        
        return new OrganizationGridViewModel(source.Id, source.Name, 
            source.Description, source.LearningPlatform, source.IsActive, source.IsExternal, source.LearningHostUrl, source.IntegrationType, source.CreatedDate, source.Environment, source.SalesforceId);
    }
}