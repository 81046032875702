import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './OrganizationGridComponent.css';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import React from 'react';
import OrganizationItem, { EnvironmentType } from './OrganizationItem'
import OrganizationGridViewModel from '../../models/Organizations/OrganizationGridViewModel'
import OrganizationStore from '../../stores/OrganizationStore';
import { ORGANIZATION_FRAMWORK_PAGE_PATH } from '../../Settings';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import { Redirect } from 'react-router';
import Select from "react-select";
import { RouterStore } from 'mobx-react-router';
import OrganizationConstants from '../../constants/OrganizationConstants';
import OrganizationModel from '../../models/Organizations/OrganizationModel'
import IntegrationType from '../../models/Organizations/OrganizationModel'
import {envTypeToString, integrationTypeToString} from '../../models/Organizations/OrganizationModel'
// import { ORGANIZATION_GRID_CY_DATA,ORGANIZATION_GRID_ITEM_CY_DATA,ORGANIZATION_GRID_PAGINATION_CY_DATA,ORGANIZATION_SEARCH_BUTTON_CY_DATA,ORGANIZATION_SEARCH_INPUT_CY_DATA } from '../../cypressDataConstants/organizationGridCyDataConstants';
import { CaughtException } from 'mobx/lib/internal';
import PaginationComponent from '../providers/PagintationComponent';
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";

interface OrganizationGridComponentProps {
    globalLoader?: GlobalLoaderStore,
    routing?: RouterStore,
    organizations: OrganizationStore
}

interface OrganizationGridComponentState {
    filterString: string,
    appliedFilterString: string,
    redirectToFramework: boolean,
    uiFilterChangeKey: number,
    hasError: boolean,
    refinedSearchDateStart:string,
    textInputError: string,
    refinedSearchDateEnd:string,
    refinedSearchStatus: string[],
    refinedSearchEnvironment: string[],
    refinedSearchIntegration: string[],
    refinedSearchLearningPlatform: string[],
    refinedSearchText: string,
    queryField: string,
    queryValue: string,
    resultData: any
}
interface ApiOrganizationResult {
    data: {
        fetcher: [{
            Id: string,
            Name: string,
            LearningPlatform: string,
            LearningPlatformHostURL: string,
            IntegrationType: string,
            Status: string,
            CreateDate: string,
        }]
    }
}
var totalIntegrations:any[];
var totalLearningPlatform: any[];
var totalStatus:any[];
var totalEnvironments:any[];

@inject('organizations', 'globalLoader', 'routing')
@observer
export default class OrganizationGridComponent extends Component<OrganizationGridComponentProps, OrganizationGridComponentState> {
    constructor(props: OrganizationGridComponentProps) {
        super(props);        
        this.state = {
            filterString: "",
            appliedFilterString: "",
            redirectToFramework: false,
            hasError: false,
            refinedSearchDateEnd:'',
            refinedSearchDateStart:'',
            refinedSearchText: '',
            refinedSearchStatus:[],
            refinedSearchEnvironment:[],
            refinedSearchLearningPlatform:[],
            refinedSearchIntegration:[],
            textInputError: '',
            queryField: "GlobalContentID",
            uiFilterChangeKey: 0,
            queryValue: "",
            resultData: null
        }
    }

    componentDidMount() {
        if(this.props.organizations.Organizations.length == 0)
        {
            this.props.globalLoader!.startLoading();
            this.props.organizations!.loadOrganizations().finally(()=>this.props.globalLoader!.finishLoading());
        }
    }

 
    onQueryFilterValueChange = (event: string) => {
        this.setState({ queryValue: event })
    }

    mapResultsToorganizationsGridViewModelObject = (data: ApiOrganizationResult) => {
        var result: OrganizationGridViewModel[] = []
        data.data.fetcher.forEach(item => {
            result.push(new OrganizationGridViewModel(item.Id, item.Name, item.LearningPlatform, item.LearningPlatformHostURL, item.IntegrationType,
                item.Status, item.CreateDate))
        });
        return result;
    }

   
    sortOrganizationById = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.Id!.localeCompare(b.Id!)) : (a.Id!.localeCompare(b.Id!)) });
    }
    sortOrganizationByStatus = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.IsActive!.toString().localeCompare(b.IsActive!.toString())) : (a.IsActive!.toString().localeCompare(b.IsActive!.toString())) });
    }
    sortOrganizationByCreateDate = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => {
            if(a.CreatedDate == undefined && b.CreatedDate== undefined) return 0; if(a.CreatedDate == undefined) return 1;  if(b.CreatedDate == undefined) return -1;
            return isDescending ? -(a.CreatedDate!.localeCompare(b.CreatedDate!)) : (a.CreatedDate!.localeCompare(b.CreatedDate!)) });
    }
    sortOrganizationBySalesforce = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => {
            if(a.SalesforceId == undefined && b.SalesforceId== undefined) return 0; if(a.SalesforceId == undefined) return 1;  if(b.SalesforceId == undefined) return -1;
            return isDescending ? -(a.SalesforceId!.localeCompare(b.SalesforceId!)) : (a.SalesforceId!.localeCompare(b.SalesforceId!)) });
    }
    sortOrganizationByEnvironment = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => {
            if(a.Environment.toString() == undefined && b.Environment.toString()== undefined) return 0; if(a.Environment.toString() == undefined) return 1;  if(b.Environment.toString() == undefined) return -1;
            return isDescending ? -(a.Environment.toString()!.localeCompare(b.Environment.toString()!)) : (a.Environment.toString()!.localeCompare(b.Environment.toString()!)) });
    }
    sortOrganizationByName = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { 
            if(a.Name == undefined && b.Name== undefined) return 0; if(a.Name == undefined) return 1;  if(b.Name == undefined) return -1;
            return isDescending ? -(a.Name!.localeCompare(b.Name!)) : (a.Name!.localeCompare(b.Name!)) });
    }
    sortOrganizationByLearningPlatform = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { 
            if(!a.LearningPlatform) return 1; if(!b.LearningPlatform) return -1;
            return isDescending ? -(a.LearningPlatform!.localeCompare(b.LearningPlatform!)) : (a.LearningPlatform!.localeCompare(b.LearningPlatform!)) });
    }
    sortOrganizationByLearningPlatformUrl = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { 
            if(!a.LearningHostUrl) return 1; if(!b.LearningHostUrl) return -1;
            return isDescending ? -(a.LearningHostUrl!.localeCompare(b.LearningHostUrl!)) : (a.LearningHostUrl!.localeCompare(b.LearningHostUrl!)) });
    }
    sortOrganizationByIntegrationType = (isDescending: boolean) => {
        this.props.organizations!.sortOrganizations((a, b) => { return isDescending ? -(a.IntegrationType!.toString().localeCompare(b.IntegrationType!.toString())) : (a.IntegrationType!.toString().localeCompare(b.IntegrationType!.toString())) });
    }

  
    handleOnChangePage = (newCurrentPage: number): Promise<any> => {
        return new Promise<any>((resolve) => {
            this.props.organizations!.CurrentPage = newCurrentPage;
            resolve(null);
        })
    }


    onFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            this.setState({ filterString: "", appliedFilterString: "" });
        } else {
            this.setState({ filterString: e.target.value });
        }
    }
    redirectToOrganizationFramework = () => {
        this.setState({redirectToFramework: true});
    }

    onFilterApply = (event: any) => {
        event.preventDefault();
        this.setState({ appliedFilterString: this.state.filterString });
    }

    onTipClicked = (event: any) => {
        this.setState({ filterString: event.target.innerText, appliedFilterString: event.target.innerText });
    }

    //Refine search section
    clearRefinedSearch = () =>{
        this.setState({
            refinedSearchDateEnd:'',
            refinedSearchDateStart:'',
            refinedSearchText:'',
            refinedSearchEnvironment:[],
            refinedSearchStatus:[],
            refinedSearchLearningPlatform:[],
            refinedSearchIntegration:[],
            uiFilterChangeKey: this.state.uiFilterChangeKey + 1
        })
    }
    onRefinedSearchTextChange = (e: any) => {
        if(this.props.organizations!.CurrentPage > 1){
            this.handleOnChangePage(1);
        }
        this.setState({ refinedSearchText: e.target.value })
    }
    onRefinedSearchIntegrationChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchIntegration: value })
    }
    onRefinedSearchLearningPlatformChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchLearningPlatform: value })
    }
    onRefinedSearchEnvironmentChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchEnvironment: value })
    }
    onRefinedSearchStatusChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchStatus: value })
    }

    onRefinedSearchDateStartChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchDateStart: value[0] })
    }
    onRefinedSearchDateEndChange = (value: any) => {
        if(this.props.organizations!.CurrentPage > 1)
            this.handleOnChangePage(1);
        this.setState({ refinedSearchDateEnd: value[0] })
    }
    getLearningPlatformList = (organizations: any[])=>{
        if(totalLearningPlatform?.length > 0)
           return totalLearningPlatform;
        let result: any[] = [];
        for (let index = 0; index < organizations.length; index++) {        
           if(!organizations[index].LearningPlatform)                 
                continue;
           if (!result.includes(organizations[index].LearningPlatform))
               result.push(organizations[index].LearningPlatform)
        }
        totalLearningPlatform = result.map((subject, index) => ({ label: subject, value: index }));
        return totalLearningPlatform.sort((a, b) => a.label.localeCompare(b.label));
    }
    getEnvironmentList = (organizations: any[])=>{
        if(totalEnvironments?.length > 0)
           return totalEnvironments;
        let result: any[] = [];
        for (let index = 0; index < organizations.length; index++) {             
           var env = envTypeToString(organizations[index].Environment)
           if (!result.includes(env))
               result.push(env)
        }
        totalEnvironments = result.map((subject, index) => ({ label: subject, value: index }));
        return totalEnvironments.sort((a, b) => a.label.localeCompare(b.label));
    }
    getStatusList = (organizations: any[])=>{
        if(totalStatus?.length > 0)
           return totalStatus;
        let result: any[] = [];
        result.push('Inactive')
        result.push('Active')

        totalStatus = result.map((subject, index) => ({ label: subject, value: index }));
        return totalStatus.sort((a, b) => a.label.localeCompare(b.label));
    }
    getIntegrationList = (organizations: any[])=>{
        if(totalIntegrations?.length > 0)
           return totalIntegrations;
        let result: any[] = [];
        for (let index = 0; index < organizations.length; index++) {             
           var integration = integrationTypeToString(organizations[index].IntegrationType)
           if (!result.includes(integration))
               result.push(integration)
        }
        totalIntegrations = result.map((subject, index) => ({ label: subject, value: index }));
        return totalIntegrations.sort((a, b) => a.label.localeCompare(b.label));
    }
    escapeRegex(string) {
        return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
    }
    refinedSearchFilterOrgs = (orgs: any[]) => {
        if (orgs == undefined)
            return [];

       
        //filter ID & name & salesforce
        if (this.state.refinedSearchText.length > 0) {
            var refinedMatch = this.escapeRegex(this.state.refinedSearchText.toLowerCase());
            orgs = orgs.filter(i => i.Id.toLowerCase().match(refinedMatch) || (i.Name && i.Name.toLowerCase().match(refinedMatch))  || (i.SalesforceId && i.SalesforceId.toLowerCase().match(refinedMatch)))
        }

        //filter Env
        if (this.state.refinedSearchEnvironment.length != 0)
            orgs = orgs.filter(org => this.state.refinedSearchEnvironment.map(x => x.label).includes(EnvironmentType[org.Environment]))

        //filter learningPlat
        if (this.state.refinedSearchLearningPlatform.length != 0)
            orgs = orgs.filter(org => this.state.refinedSearchLearningPlatform.map(x => x.label).includes(org.LearningPlatform))

        //filter Status
        if (this.state.refinedSearchStatus.length != 0)
            orgs = orgs.filter(org => this.state.refinedSearchStatus.map(x => x.label).includes(org.IsActive ? 'Active' : 'Inactive'))

        //filter Integration
        if (this.state.refinedSearchIntegration.length != 0)
            orgs = orgs.filter(org => this.state.refinedSearchIntegration.map(x => x.label).includes(integrationTypeToString(org.IntegrationType)))
         
        //filter date
        if (this.state.refinedSearchDateStart) {
            var refinedSearch = this.state.refinedSearchDateStart.toISOString()
            orgs = orgs.filter(org => refinedSearch < org.CreatedDate)
        }
        if (this.state.refinedSearchDateEnd) {
            var refinedSearch = this.state.refinedSearchDateEnd.toISOString()
            orgs = orgs.filter(org => refinedSearch > org.CreatedDate)
        }
        return orgs; 
    }
    //End section




    ResetCurrentSort = (idString: string) => {
        var elements = document.getElementsByClassName("sortable");
        while (elements.length)
            elements[0].classList.remove("sortable");

        var sortTarget = document.getElementById(idString);
        sortTarget?.classList.add("sortable");
    }
    renderGridHeaderItem = (title: string, onSort: (isDescending: boolean)=>void) => {
        return <th scope='col'>
            <div className="csod-grid-header-container">
                {title}
                <div className = "csod-sorting-container">
                    
                    <div id={title + 'up'} className="csod-sort-up "  onClick= {() => { onSort(true); 
                        this.ResetCurrentSort(title + 'up')} }><div className="csod-icon-sort-up">▲</div></div>
                    <div id={title + 'down'} className="csod-sort-down" onClick= {() =>{ onSort(false)
                        this.ResetCurrentSort(title + 'down')} }><div className="csod-icon-sort-down">▼</div></div>
                </div>
            </div>
        </th>
    }


    static getDerivedStateFromError(error: CaughtException) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    onCreateNewOrgClick = () => {
        this.props.organizations!.CurrentOrganization = new OrganizationModel();
        this.props.organizations!.FrameworkMode = FrameworkModes.Create;
        this.redirectToOrganizationFramework();
    }
    render() {
        if (this.props.globalLoader!.isLoading) {
            return null;
        }
        if (this.state.redirectToFramework) {
            return <Redirect push={true} to = {ORGANIZATION_FRAMWORK_PAGE_PATH}/>
        }
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        var OrganizationList: OrganizationModel[] = this.state.resultData ? this.state.resultData : this.props.organizations?.Organizations;

        OrganizationList = this.refinedSearchFilterOrgs(OrganizationList)
        var currentPage = this.props.organizations!.CurrentPage;
        var lastItemPerCurrentPageNumber = (this.props.organizations!.CurrentPage - 1) * OrganizationConstants.ITEMS_PER_PAGE_COUNT + OrganizationConstants.ITEMS_PER_PAGE_COUNT;
        if (lastItemPerCurrentPageNumber > this.props.organizations!.Organizations!.length) {
            lastItemPerCurrentPageNumber = this.props.organizations!.Organizations!.length;
        }

        return <div className="csod-organization-data-container">

            <div className="csod-search-container">
                <div className="csod-organization-data-count-title">Content Organization ({OrganizationList.length})</div>
                <button className = "csod-button less-rounded-corners blue marginFixOrg" onClick={this.onCreateNewOrgClick}>{OrganizationConstants.ADD_NEW_ORGANIZATION}</button>
                <div className='refinement-div marginFixOrg'>
                    <div style={{display:'inline-block'}} className='advanced-search-wrapper wrapper-top'>
                        <div className="csod-search-add-new-container">
                                <div className="csod-search-field-tips-container max-width-search">
                                    <input type="text" id='refined-text-search-input' list="providers" style={{width:'inherit'}} 
                                    key={this.state.uiFilterChangeKey} 
                                    className="csod-input-search-tb less-rounded-corners" placeholder={"Search ID or Name or Salesforce"} 
                                    defaultValue={this.state.refinedSearchText} onChange={this.onRefinedSearchTextChange}  />
                                </div>
                        </div>
                        <div className='advanced-search-buttons'>
                            <div className='select-input-org'>
                                <Select placeholder="Learning Platform" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        menuPortalTarget={document.querySelector('body')}
                                        options={this.getLearningPlatformList(OrganizationList)} 
                                        onChange={this.onRefinedSearchLearningPlatformChange} 
                                        value={this.state.refinedSearchLearningPlatform}
                                        className='select-class'/>
                            </div>
                            <div className='select-input-org'>
                                <Select placeholder="Environment" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getEnvironmentList(OrganizationList)}                               
                                        onChange={this.onRefinedSearchEnvironmentChange} 
                                        value={this.state.refinedSearchEnvironment}
                                        className='select-class'/>
                            </div>
                            <div className='select-input-org'>
                                <Select placeholder="Integrations" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getIntegrationList(OrganizationList)}                               
                                        onChange={this.onRefinedSearchIntegrationChange} 
                                        value={this.state.refinedSearchIntegration}
                                        className='select-class'/>
                                
                            </div>

                            <div className='select-input-org'>
                                <Select placeholder="Status" myFontSize="20px" isMulti={true} 
                                        important={true} 
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: base => ({ ...base, fontFamily:'Segoe UI'  }) }}
                                        options={this.getStatusList(OrganizationList)}                               
                                        onChange={this.onRefinedSearchStatusChange} 
                                        value={this.state.refinedSearchStatus}
                                        className='select-class' />
                            </div>
                            
                            <div className="select-input-org">
                                <Flatpickr className="class-start-date" key={this.state.uiFilterChangeKey} placeholder="From date" onChange={(date) => this.onRefinedSearchDateStartChange(date)}  />
                            </div>
                            <div className="select-input-org">
                                <Flatpickr className="class-end-date" key={this.state.uiFilterChangeKey} placeholder="To date" onChange={(date) => this.onRefinedSearchDateEndChange(date)}  />
                            </div>
                            <div className='clear-button-wrapper select-input-org'>
                                <button onClick={() => this.clearRefinedSearch()} type="button" className='clear-button button csod-button less-rounded-corners '> Clear all filters</button>
                            </div>
                        </div>
                    </div>
                </div>
                {OrganizationList.length === 0 ? <h2>No Orgs Found </h2> :

                    <div>
                        <table className="csod-organization-data-grid">
                            <thead>
                                <tr>
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_ID, this.sortOrganizationById)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_NAME, this.sortOrganizationByName)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM, this.sortOrganizationByLearningPlatform)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_ENVIRONMENT, this.sortOrganizationByEnvironment)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_INTEGRATION_TYPE, this.sortOrganizationByIntegrationType)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_SALESFORCE, this.sortOrganizationBySalesforce)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM_HOST_URL, this.sortOrganizationByLearningPlatformUrl)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_STATUS, this.sortOrganizationByStatus)} 
                                    {this.renderGridHeaderItem(OrganizationConstants.ORGANIZATION_DATA_GRID_CREATE_DATE, this.sortOrganizationByCreateDate)} 
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    console.log("current page: " + (this.props.organizations!.CurrentPage - 1))
                                }
                                {
                                    OrganizationList.slice((this.props.organizations!.CurrentPage - 1) * OrganizationConstants.ITEMS_PER_PAGE_COUNT, lastItemPerCurrentPageNumber)
                                        .map((item) => { return <OrganizationItem Organization={OrganizationGridViewModel.Map(item)} key={item.Id} 
                                        RedirectToUpdate={this.redirectToOrganizationFramework} /> })
                                }
                            </tbody>
                        </table>
                        {OrganizationList!.length <= OrganizationConstants.ITEMS_PER_PAGE_COUNT ? null : <PaginationComponent currentPageIndex={currentPage} listPages={this.props.organizations!.TotalPages} handleChangePage={this.handleOnChangePage} />} 

                    </div>}
            </div>
        </div>
    }
}