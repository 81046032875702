export const fetchCoursesPerOrg = `query fetchCoursesPerOrg($OrgId: String) {
  fetchCoursesPerOrg(OrgId: $OrgId){
    BundlePresignedUrls
    MetadataLanguages
  }
}
`

export const listProviders = `query Providers {
  providers {
    Id
    Type
    Name
    AuthenticationUrl
    CourseSyncUrl
    SyncTime
    LastSuccessfulModifiedDateCourse
    Scope
    Audience
    AuthenticationType
    Abbreviation
    IsExcelProvider
    LastDateNotified
    IsMobileSupported
    LoginUrl
    LaunchUrl
    LTIClientId
    JWKsURL
    PublicKey
    FreeFormParams{
      Key
      Value
    }
    IsActive
    IsCCC
    IsStrictSchema
    ProviderOfferingType
  }
}`


export const getOrganization = `query GetOrganization($Id: String!) {
  getOrganization(Id: $Id) {
    Id
    Name
    Description
    LearningPlatform
    AiccUrl
    AddUserEmails
    SalesforceId
    TenantIdentifier
    MetadataLanguages
    OrgSettingCollection{
      Key
      Value
    }
    IsActive
    IsExternal
    LearningHostUrl
    IntegrationType
    Environment
    OrgBundle{
      Id
      BundleSubscriptionDate
    }
    CreatedDate
    Sso {
      SpCertificate
      Type
      RequestDestination
      RequestIssuer
      ResponseIssuer
      IsSpEncrypted
      IdpCertificate
      FreeformFields {
        Key
        Value
      }
      AssertionConsumerServiceUrl
      Audience
    }
    Authentication {
      AuthType
      Username
      Password
      ClientId
      ClientSecret
    }
    XApi {
      AuthenticationTokenUrl
      ClientId
      ClientSecret
      Scope
      GrantType
      XApiEndpoint
      XApiAccountHomepage
      FreeformFields {
        Key
        Type
        Value
      }
      AuthType
    }
  }
}`
export const getOrganizations = `query GetOrganizations {
  getOrganizations {
    Id
    Name
    Description
    LearningPlatform
    AiccUrl
    AddUserEmails
    TenantIdentifier
    IsActive
    IsExternal
    MetadataLanguages
    SalesforceId
    OrganizationModifications{
      LastModifiedBy
      OrganizationModified
    }
    OrgSettingCollection{
      Key
      Value
    }
    SubscriberUri
    LearningHostUrl
    IntegrationType
    Environment
    OrgBundle{
      Id
      BundleSubscriptionDate
      IsActive
    }
    CreatedDate
    Sso {
      SpCertificate
      Type
      RequestDestination
      RequestIssuer
      ResponseIssuer
      IdpCertificate
      IsSpEncrypted
      FreeformFields {
        Key
        Value
      }
      AssertionConsumerServiceUrl
      Audience
    }
    Authentication {
      AuthType
      Username
      Password
      ClientId
      ClientSecret
    }
    XApi {
      AuthenticationTokenUrl
      ClientId
      ClientSecret
      Scope
      GrantType
      XApiEndpoint
      XApiAccountHomepage
      FreeformFields {
        Key
        Type
        Value
      }
      AuthType
    }
  }
}`
export const getCurrentList = `query CurrentData {
  currentData {
    DataType
    LastSuccessfulSyncDate
    PK
    ProviderId
    SK
    StateType
    TrainingType
    Url
  }
}`

export const providerDataList = `query ProviderDataList($Id: String!, $Ciid: String!) {
  providerDataList(Id: $Id, Ciid: $Ciid) {
    LastSuccessfulSyncDate
    ErrorMessage
    ErrorType
    ProviderId
    TrainingsCount
    SyncDate
    CorrelationId
    StateType
    TrainingType
    Url
  }
}`

export const getSettings = `query Settings($Id: String!, $TrainingType: String!) {
  settings(Id: $Id, TrainingType: $TrainingType) {
    DirectSubscriptionCIIDs
    Id
    IsCustomSubjectMapping
    IsCustomCourseMapping
    IsForCuration
    DirectSubscriptionCIIDData{
      Ciid
      SubscriptionUri
    }
    TestPortals {
      Ciid
      IsEnabled
      Url
    }
    Notes
  }
}`

export const fetchPackagesWithQuery = `query Fetcher(
  $QueryParameterColName: String!
  $QueryParameter: String!
  $StartDate: String
  $EndDate: String
) {
  fetcher(
    QueryParameterColName: $QueryParameterColName
    QueryParameter: $QueryParameter
    StartDate: $StartDate
    EndDate: $EndDate
  ) {
    Id
    GlobalContentID
    SubscriberUri
    PackageID
    ProviderID
    CIID
    ProcessSource
    CreateDate
    BundleID
  }
}`

export const getBundles = `query GetBundles($OfficialCCaBundlesOnly: Boolean) {  
  getBundles(OfficialCCaBundlesOnly:$OfficialCCaBundlesOnly) {
    Description
    Id
    IsActive
    Items {
      Description
      Id
      Name
    }
    Name
    Status
  }
}`

export const getAdditionalDetailsForProvider = `query Provider($Id: String!, $TrainingType: String!) {
  provider(Id: $Id, TrainingType: $TrainingType) {
    Id
    Type
    Name
    AuthenticationUrl
    CourseSyncUrl
    SyncTime
    LastSuccessfulModifiedDateCourse
    Scope
    Audience
    AuthenticationType
    Abbreviation
    IsExcelProvider
    LastDateNotified
    IsMobileSupported
    LoginUrl
    LaunchUrl
    JWKsURL
    LTIClientId
    PublicKey
    FreeFormParams{
      Key
      Value
    }
    IsActive
    IsStrictSchema
    ProviderOfferingType
    ClientID
    ClientSecretKey
    AlertEmails
    ProviderModifications{
      LastModifiedBy
      ProviderModified
    }
    SupportInline
    Logo
    Description
    SubjectsMapping {
      Key
      Value
    }
    CourseMapping {
      Key
      Value
    }
    ProgressApiBasicAuthentication {
      UserId
      Username
      Password
    }
    SsoLastModified
    SsoData {
      ssoId
      serviceProvider
      acsUrl
      algorithm
      timeStampFormat
      isInternal
      issuer
      signatureMode
      audience
      expirationDate
      spInitiated
      authRequestIssuer
      authRequestDestination
      skipSignatureValidation
      clientPublicCertificate
      signatureWithinAssertion
      includeIssuerInResponse
      queryStringParameters {
        querystringParameterName
        samlAttributeName
      }
      authenticationParameters {
        key
        value
      }
      additionalSettings {
        name
        value
        type
        position
      }
      outboundSsoUrl
    }
  }
}`


export const providerData = `query ProviderData($id: String) {
  providerData(id: $id) {
    DataType
    LastSuccessfulSyncDate
    PK
    ProviderID
    SK
    StateType
    TrainingType
    Url
  }
}`

export const getProviderDatatSettings = `query ProviderDataList {
  providerDataList {
    DataType
    LastSuccessfulSyncDate
    PK
    ProviderId
    SK
    StateType
    TrainingType
    Url
  }
}`


export const UpdateCCMSBundle = `
query UpdateCCMSBundle($bundleId: String!, $providerId: String!) {
  updateCCMSBundle(bundleId: $bundleId, providerId: $providerId)
}`


export const GetCuratedBundles = `
query GetBundles {
  getBundles {
    Id
    Name
    Description
    IsActive
    Status
    Items {
      Description
      Id
      Name
    }
  }
}`


export const getCuratedCourses = `query CuratedCourses($BundleId: String, $Gcids: [String]) {
  curatedCourses(BundleId: $BundleId, Gcids: $Gcids) {
    ID
    ProviderID
    GlobalContentID
    IsMobile
    Title
    LastModifiedUTC
    ExpectedRetirementDateUT
    Subjects
    Modalities
    Description
    IsActive
    Thumbnail
    Languages
    IsForBundleRemoval
    Duration
    DeeplinkLaunchURL
    ProviderName
    ProviderLogo
    Skills
    BundleID
    BundleName
    EmbedDeeplinkURL
    DurationTimeValue
    DurationTime
  }
}
`


export const getCuratedCoursesAsUrl = `query CuratedCoursesAsUrl($BundleId: String, $Gcids: [String]) {
  curatedCoursesAsUrl(BundleId: $BundleId, Gcids: $Gcids)       
}
`

export const getPersignedUrl = `query GetPresignedUrl(
  $BundleID: String,
  $BundleName: String,
  $Gcids: [String]
  $BundleIds: [String]
  $SourceType: String,
  $ResultsInEmail:Boolean,
  $EmailAddress: String
) {
  getPresignedUrl(BundleID: $BundleID,BundleName: $BundleName, Gcids: $Gcids, BundleIds: $BundleIds, SourceType: $SourceType,ResultsInEmail:$ResultsInEmail,EmailAddress:$EmailAddress)
}
`