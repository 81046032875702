import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProviderGridViewModel from '../../models/ProvidersGrid/ProviderGridViewModel';
import "./ProviderItem.css";
import { providerOfferingTypeToString } from '../../models/ProviderDetails/ProviderOfferingType';
import { TrainingType } from '../../models/ProviderDetails/TrainingType';
import ProviderConstants from '../../constants/providersconstants';
import ProviderStore from '../../stores/ProvidersStore';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';
import GraphQLHelper from '../../helpers/common/GraphQLHelper';
import GlobalLoaderStore from '../../stores/GlobalLoaderStore';
import LabelComponent from '../common/inputs/LabelComponent';
import CodeHelpers from '../../helpers/common/CodeHelpers';
import { CourseMapping, SubjectMapping } from '../../models/ProviderDetails/ProviderDetailsExpansion';
import SSOConfigModel from '../../models/SSOConfig/SSOConfigModel';
import ProviderToolData from '../../models/TestSubscription/ProviderToolData';
import SsoData from '../../models/SSOConfig/SsoData';
import UpsertProviderModel from '../../models/ProviderDetails/UpsertProviderModel';
import { AuthenticationType } from '../../models/ProviderDetails/AuthenticationType';
import SyncStatusSuccessImg from '../../resources/csod-green-checkmark.svg';
import SyncStatusFailedImg from '../../resources/csod-red-cross.svg';

interface ProviderItemProps {
    Provider: ProviderGridViewModel,
    RedirectToUpdate: () => void,
    providers?: ProviderStore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string
}

interface ProviderItemState {
    Provider: ProviderGridViewModel | null;
}

@inject("providers", "globalLoader")
@observer
export default class ProviderItem extends Component<ProviderItemProps, ProviderItemState> {
    constructor(props: ProviderItemProps) {
        super(props);
        this.state = {
            Provider: props.Provider
        }
    }

    private mapProviderAdditionalInfo = (pd: any) => {
        if(pd.data && pd.data.provider) {
            var pulledProvider = pd.data.provider as UpsertProviderModel;
            try{
                this.props.providers!.CurrentProviderDetailsExpansion.ContactEmails = pulledProvider.AlertEmails? pulledProvider.AlertEmails.join(",") : "";
            }
            catch{
                console.log('No email found')
            }
            this.props.providers!.CurrentProvider!.ProviderModifications = pulledProvider.ProviderModifications;
            this.props.providers!.CurrentProviderDetailsExpansion.IsExcelProvider = pulledProvider.IsExcelProvider;
            this.props.providers!.CurrentProvider!.SubjectsMapping = CodeHelpers.ArrayKeyValueToString(pulledProvider.SubjectsMapping!);
            this.props.providers!.CurrentProvider!.CourseMapping = pulledProvider.CourseMapping == null ? [] : CodeHelpers.ArrayKeyValueToString(pulledProvider.CourseMapping!);

            this.props.providers!.CurrentProvider!.Logo = pulledProvider.Logo;
            this.props.providers!.CurrentProvider!.SupportInline = pulledProvider.SupportInline;
            this.props.providers!.CurrentProvider!.Description = pulledProvider.Description;
            
            var username = pulledProvider.ProgressApiBasicAuthentication?.Username!;
            var password = pulledProvider.ProgressApiBasicAuthentication?.Password!;
            this.props.providers!.CurrentProgressApiConfig!.Username = username;
            this.props.providers!.CurrentProgressApiConfig!.Password = password;

            if(pulledProvider.AuthenticationType === AuthenticationType.Basic) {
                this.props.providers!.CurrentBasicAuthConfig!.Username = pulledProvider.ClientID;
                this.props.providers!.CurrentBasicAuthConfig!.Password = pulledProvider.ClientSecretKey;
            } else if (pulledProvider.AuthenticationType === AuthenticationType.oAuth2) {
                this.props.providers!.CurrentOAuth2Config.ClientID = pulledProvider.ClientID;
                this.props.providers!.CurrentOAuth2Config.ClientSecretKey = pulledProvider.ClientSecretKey;
                this.props.providers!.CurrentOAuth2Config.AuthenticationUrl = pulledProvider.AuthenticationUrl;
                this.props.providers!.CurrentOAuth2Config.Scope = pulledProvider.Scope;
                this.props.providers!.CurrentOAuth2Config.Audience = pulledProvider.Audience;
            } else if (pulledProvider.AuthenticationType === AuthenticationType.ApiKey) {
                this.props.providers!.CurrentApiKeyConfig.ClientSecretKey = pulledProvider.ClientSecretKey;
                this.props.providers!.CurrentApiKeyConfig.HeaderName = pulledProvider.ClientID;

            }
            var ssoData = pd.data.provider.SsoData as SsoData;
            if(ssoData) {
                this.props.providers!.CurrentSSOConfigModel = SSOConfigModel.ComposeSSOConfigModel(ssoData);
            }
        }
    }

    private mapProviderSettings = (settings: any) => {
        var providerSettings = settings.data?.settings as ProviderToolData;
        if(providerSettings) {
            this.props.providers!.CurrentProviderDetailsExpansion.CIIDs = providerSettings.DirectSubscriptionCIIDs;
            this.props.providers!.CurrentProviderDetailsExpansion.DirectSubscriptionCIIDData = providerSettings.DirectSubscriptionCIIDData;
            this.props.providers!.CurrentProviderDetailsExpansion.IsForCuration = providerSettings.IsForCuration;
            this.props.providers!.CurrentProviderDetailsExpansion.SubjectMappingType = providerSettings.IsCustomSubjectMapping? SubjectMapping.Custom : SubjectMapping.CSODPreset;            
            this.props.providers!.CurrentProviderDetailsExpansion.CourseMappingType = providerSettings.IsCustomCourseMapping? CourseMapping.Custom : CourseMapping.None;
            if(providerSettings.IsCustomSubjectMapping) {
                this.props.providers!.CurrentProviderDetailsExpansion.CustomTaxonomy = this.props.providers!.CurrentProvider!.SubjectsMapping;
            }
            if(providerSettings.IsCustomCourseMapping) {
                this.props.providers!.CurrentProviderDetailsExpansion.CustomCourseMapping = this.props.providers!.CurrentProvider!.CourseMapping;
            }
            Object.values(this.props.providers!.CurrentTestSubscriptionConfig!).forEach(i => {
                providerSettings.TestPortals.forEach(ps => {
                    if(i.url === ps.Url) {
                        i.CIID = ps.Ciid;
                        i.isChecked = ps.IsEnabled;
                    }
                })
            })
            this.props.providers!.CurrentProviderDetailsExpansion.ProviderDescription = providerSettings.Notes;
        }
    }

    private GetProviderAdditionalInfo = () => {
        this.props.globalLoader?.startLoading();
        return GraphQLHelper.GetProviderAdditionalInfoGQCall(this.props.providers?.CurrentProvider!).then(pd => {
            this.mapProviderAdditionalInfo(pd);
        }).catch( err => {
            console.log(err);
            this.mapProviderAdditionalInfo(err);
        }).finally(() => {
            this.GetProviderSettings();
            this.props.globalLoader?.finishLoading();
        });
    }

    private GetProviderSettings = () => {
        this.props.globalLoader?.startLoading();
        return GraphQLHelper.GetSettingsGQCall(this.props.providers?.CurrentProvider!).then(s => {
            this.mapProviderSettings(s);
        }).catch(err => {
            console.log(err);
            this.mapProviderSettings(err);
        }).finally(() => {
            this.props.globalLoader?.finishLoading();
        })
    }

    private onProviderClick = () => {
        this.props.providers!.FrameworkMode = FrameworkModes.Edit;
        this.props.providers?.cleanProviderData();
        this.props.providers!.loadCurrentProvider(this.props.Provider);
        this.GetProviderAdditionalInfo();
        this.props.RedirectToUpdate();
    }
    private getSyncStatusImage = (providerSyncStatus:any) => {
        if(providerSyncStatus === "success") {
            return <img src= {SyncStatusSuccessImg}/>
        } else 
            return <img src= {SyncStatusFailedImg}/>
    }

    render() {
        return <tr  cy-data={this.props.cy_data}>
            <td data-th={ProviderConstants.PROVIDERS_GRID_SYNC_STATUS_TITLE} className = 'csod-table-td csod-provider-name'>{this.getSyncStatusImage(this.props.Provider.SyncStatus)}</td>
            <td onClick={this.onProviderClick} data-th={ProviderConstants.PROVIDERS_GRID_NAME_TITLE} className = 'csod-table-td csod-provider-name'>{this.props.Provider.Name}</td>
            <td data-th={ProviderConstants.PROVIDERS_GRID_ID_TITLE} className = 'csod-table-td' > <LabelComponent value={this.props.Provider.ID} isAbleToCopy={true} isHidden={false} /> </td>
            <td data-th={ProviderConstants.PROVIDERS_GRID_ABBREV_TITLE} className = 'csod-table-td' >{this.props.Provider.Abbrev}</td>
            <td data-th={ProviderConstants.PROVIDERS_GRID_OFFERING_TITLE} className = 'csod-table-td'>{providerOfferingTypeToString(this.props.Provider.OfferingType)}</td>
            <td data-th={ProviderConstants.PROVIDERS_GRID_TRAINING_TITLE} className = 'csod-table-td'>{TrainingType[this.props.Provider.ProviderTrainingType]}</td>
        </tr>
    }
}