import { v4 as uuid } from 'uuid';
import { AuthenticationType } from "./AuthenticationType";
import { ProviderOfferingType } from "./ProviderOfferingType";
import { TrainingType } from "./TrainingType";



export const CSOD_PREDEFINED_COURSE_MAPPING = `{
    \t"id": "ID",
    \t"title": "Title",
    \t"image_id": "Thumbnail",
    \t"updated_at": "LastModifiedUTC",
    \t"tag_list": "Keywords",
    \t"version_string": "Version",
    \t"level": "Level",
    \t"course_description": "Description",
    }`;

export const CSOD_PREDEFINED_SUBJECTS_MAPPING = `{
\t"Business Skills": "Business Skills",
\t"Compliance": "Compliance",
\t"Continuing Education & Certifications": "Continuing Education & Certifications",
\t"Creative": "Creative",
\t"Health & Wellness": "Health & Wellness",
\t"Industry Specific": "Industry Specific",
\t"Leadership & Management": "Leadership & Management",
\t"Office Productivity": "Office Productivity",
\t"Personal Development": "Personal Development",
\t"Sales & Service": "Sales & Service",
\t"Technology": "Technology"
}`;
export interface KeyValue {
    Key: string,
    Value: string
}
export class KeyValueInput implements KeyValue {
    constructor (public Key: string = "",
    public Value: string = "") { }
}
export class ProviderModifications{
    constructor(
        public LastModifiedBy: string,
        public ProviderModified: string
    ){}
}
export default class ProviderModel {
    constructor(

        public SyncStatus?: string,
        
        public Id?: string,
        
        public Type?: TrainingType,
        
        public Name?: string,
        
        public AuthenticationUrl?: string,
        
        public Scope?: string,
        
        public Audience?: string,
        
        public CourseSyncUrl?: string,
        
        public SyncTime?: string,
        
        public LastSuccessfulModifiedDateCourse?: string,
        
        public AuthenticationType?: AuthenticationType,
        
        public Abbreviation?: string,
        
        public LastDateNotified?: string,
        
        public Logo?: string,
        
        public Description?: string,
        public ProviderModifications?: [ProviderModifications],
        public IsMobileSupported: boolean = false,
        public LoginUrl?: string,
        public LaunchUrl?: [string], 
        public JWKsURL?: string,
        public LTIClientId?: string, 
        public PublicKey?: string, 
		public FreeFormParams?: KeyValue[] | null, 
        public IsStrictSchema: boolean = false,
        
        public SupportInline: Boolean = true,

        public IsActive: boolean = true,
        
        public SubjectsMapping: string = CSOD_PREDEFINED_SUBJECTS_MAPPING,

        public CourseMapping: string = CSOD_PREDEFINED_COURSE_MAPPING,

        public ProviderOfferingType?: ProviderOfferingType,
        
        public ForceSync: boolean = false,

        public IsCCC:boolean = false,
        
        ) 
    {  
        if(this.Id == null) {
            this.Id = uuid();
        }  
    }
}