import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import OrganizationGridViewModel from '../../models/Organizations/OrganizationGridViewModel';
import "./OrganizationItem.css";
import OrganizationConstants from '../../constants/OrganizationConstants';
import Organizationstore from '../../stores/OrganizationStore';
import { FrameworkModes } from '../../models/ProviderFramework/FrameworkMode';

import GlobalLoaderStore from '../../stores/GlobalLoaderStore';


interface OrganizationItemProps {
    Organization: OrganizationGridViewModel,
    RedirectToUpdate: () => void,
    organizations?: Organizationstore,
    globalLoader?: GlobalLoaderStore,
    cy_data?: string
}
enum IntegrationType{
    AICC_SNS,
    Deeplink,
    AICC_Manual,
    AICC_HACP
}
export enum EnvironmentType{
    Production = 0,
    Stage = 1
}

interface OrganizationItemState {
    Organization: OrganizationGridViewModel | null;
}

@inject("organizations", "globalLoader")
@observer
export default class OrganizationItem extends Component<OrganizationItemProps, OrganizationItemState> {
    constructor(props: OrganizationItemProps) {
        super(props);
        this.state = {
            Organization: props.Organization
        }
    }

    private onOrganizationClick = () => {
        this.props.organizations!.FrameworkMode = FrameworkModes.Edit;
        this.props.organizations?.cleanOrganizationData();
        this.props.organizations!.loadCurrentOrganization(this.props.Organization);
        this.props.RedirectToUpdate();
    }
    
    render() {
        return <tr onClick={()=>this.onOrganizationClick()} cy-data={this.props.cy_data}>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_ID} className = 'csod-table-td csod-Organization-name'>{this.props.Organization.Id}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_NAME} className = 'csod-table-td' >{this.props.Organization.Name}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM} className = 'csod-table-td' >{this.props.Organization.LearningPlatform}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_ENVIRONMENT} className = 'csod-table-td' >{EnvironmentType[this.props.Organization.Environment]}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_INTEGRATION_TYPE} className = 'csod-table-td'>{IntegrationType[this.props.Organization.IntegrationType]}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_SALESFORCE} className = 'csod-table-td' >{this.props.Organization.SalesforceId}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_LEARNING_PLATFORM_HOST_URL} className = 'csod-table-td'>{this.props.Organization.LearningHostUrl}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_STATUS} className = 'csod-table-td' >{this.props.Organization.IsActive ? 'Active' : 'Inactive'}</td>
            <td data-th={OrganizationConstants.ORGANIZATION_DATA_GRID_CREATE_DATE} className = 'csod-table-td' >{ new Date(this.props.Organization.CreatedDate).toLocaleString()}</td>
        </tr>
    }
}