import CodeHelpers from "../../helpers/common/CodeHelpers";
import Oauth2Config from "./Oauth2Config";
import ProviderDetailsExpansion, { SubjectMapping,CourseMapping } from "./ProviderDetailsExpansion";
import ProviderModel from "./ProviderModel";
import SSOConfigModel from "../SSOConfig/SSOConfigModel";
import SsoDataInput from "../SSOConfig/SsoDataInput";
import BasicAuthConfig from "./BasicAuthConfig";
import { AuthenticationType } from "./AuthenticationType";
import ApiKeyConfig from "./ApiKeyConfig";

export default class UpsertProviderModel {
    constructor(
        public Id: string,
        public Type: string,
        public Name: string,
        public AuthenticationUrl: string,
        public CourseSyncUrl: string,
        public SyncTime: string,
        public LastSuccessfulModifiedDateCourse: string,
        public Scope: string,
        public Audience: string,
        public AuthenticationType: number,
        public Abbreviation: string,
        public IsCCC:boolean,
        public IsExcelProvider: boolean,
        public LastDateNotified: string,
        public IsMobileSupported: boolean,
        public IsActive: boolean,
        public IsStrictSchema: boolean,
        public ProviderOfferingType: number,
        public ClientID: string,
        public ClientSecretKey: string,
        public AlertEmails: string[] | null,
        public SubjectsMapping: KeyValueModel[] | null,
        public ProgressApiBasicAuthentication: BasicAuthConfig | null,
        public SsoData: SsoDataInput | null,
        public ProviderModifications: any,
        public LoginUrl:  string | null,
        public LaunchUrl:  [string] | null, 
        public JWKsURL: string | null,
        public LTIClientId:  string | null,
        public PublicKey:  string | null,
		public FreeFormParams: KeyValue[] | null, 
        
        public Description: String | null,
        public Logo: String | null,
        public SupportInline: Boolean,
        public CourseMapping: KeyValueModel[] | null,
    ) { }

    public static ComposeUpsertProviderModel = (provider: ProviderModel, oAuth2Config: Oauth2Config, apiKeyConfig: ApiKeyConfig, basicAuthConfig: BasicAuthConfig, providerDetailsExpansion: ProviderDetailsExpansion, ssoConfig: SSOConfigModel): UpsertProviderModel => {
        let taxonomyKeyValues: KeyValueModel[] = [];
        let courseMappingKeyValues: KeyValueModel[] = [];
        let ssoConfigForUpsert = ssoConfig.EnableSSO ? SsoDataInput.ComposeSsoDatInput(ssoConfig) : null;
        let emails: string[] | null = CodeHelpers.IsStringNullOrEmpty(providerDetailsExpansion.ContactEmails) ? null : providerDetailsExpansion.ContactEmails.split(",");

        if (!CodeHelpers.IsStringNullOrEmpty(provider?.SubjectsMapping)) {
            if (providerDetailsExpansion.SubjectMappingType === SubjectMapping.Custom && !CodeHelpers.IsStringNullOrEmpty(providerDetailsExpansion.CustomTaxonomy)) {
                taxonomyKeyValues = CodeHelpers.ToKeyValue(providerDetailsExpansion.CustomTaxonomy);
            } else {
                taxonomyKeyValues = CodeHelpers.ToKeyValue(provider?.SubjectsMapping!);
            }
        }
        if (!CodeHelpers.IsStringNullOrEmpty(provider?.CourseMapping)) {
            if (providerDetailsExpansion.CourseMappingType === CourseMapping.Custom && !CodeHelpers.IsStringNullOrEmpty(providerDetailsExpansion.CustomCourseMapping)) {
                courseMappingKeyValues = CodeHelpers.ToKeyValue(providerDetailsExpansion.CustomCourseMapping);
            } else {
                courseMappingKeyValues = CodeHelpers.ToKeyValue(provider?.CourseMapping!);
            }
        }

        var clientIdAndSecretKeyModel = UpsertProviderModel.setupClientIdAndSecretKey(basicAuthConfig, oAuth2Config, apiKeyConfig,  provider.AuthenticationType)
        var result = new UpsertProviderModel(
            provider?.Id!,
            provider?.Type!,
            provider?.Name!,
            oAuth2Config?.AuthenticationUrl!,
            provider?.CourseSyncUrl!,
            provider?.SyncTime!,
            new Date().toLocaleDateString(),
            oAuth2Config.Scope!,
            oAuth2Config.Audience!,
            provider?.AuthenticationType!,
            provider?.Abbreviation!,
            provider?.IsCCC!,
            providerDetailsExpansion.IsExcelProvider!,
            provider?.LastDateNotified!,
            provider?.IsMobileSupported!,
            provider?.IsActive,
            provider?.IsStrictSchema!,
            provider?.ProviderOfferingType!,
            clientIdAndSecretKeyModel.Username!,
            clientIdAndSecretKeyModel.Password!,
            emails,
            taxonomyKeyValues,            
            null, //Progress API need to be empty 
            ssoConfigForUpsert, 
            provider?.ProviderModifications,
            provider?.LoginUrl!,
            provider?.LaunchUrl!,
            provider?.JWKsURL!,
            provider?.LTIClientId!,
            provider?.PublicKey!,
            provider?.FreeFormParams,
            provider?.Description!,
            provider?.Logo!,
            provider?.SupportInline!,
            courseMappingKeyValues
        )
        console.log(result);
        return result;
    }

    public static setupClientIdAndSecretKey = (basicAuthConfig: BasicAuthConfig, oauth2Config: Oauth2Config, apiKeyConfig: ApiKeyConfig, authType?: AuthenticationType): BasicAuthConfig => {
        var clientIdAndSecretKeyModel = new BasicAuthConfig();
        if (authType === AuthenticationType.Basic) {
            clientIdAndSecretKeyModel.Username = basicAuthConfig.Username;
            clientIdAndSecretKeyModel.Password = basicAuthConfig.Password;
        } else if (authType === AuthenticationType.oAuth2) {
            clientIdAndSecretKeyModel.Username = oauth2Config.ClientID;
            clientIdAndSecretKeyModel.Password = oauth2Config.ClientSecretKey;
            clientIdAndSecretKeyModel.AuthUrl = oauth2Config.AuthenticationUrl;
        } else if (authType === AuthenticationType.ApiKey) {
            clientIdAndSecretKeyModel.Username = apiKeyConfig.HeaderName
            clientIdAndSecretKeyModel.Password = apiKeyConfig.ClientSecretKey;
        }        
        return clientIdAndSecretKeyModel;
    }
}

export interface KeyValue {
    key: string,
    value: string
}
export class ProviderModifications{
    constructor(
        public LastModifiedBy: string,
        public ProviderModified: string
    ){}
}
export interface KeyValueModel {
    Key: string,
    Value: string
}