export default class OrganizationDataConstants {
    public static readonly ITEMS_PER_PAGE_COUNT = 100;
    public static readonly ORGANIZATION_DATA_GRID_ID: string = "ID";
    public static readonly ORGANIZATION_DATA_GRID_NAME: string = "Name";
    public static readonly ORGANIZATION_DATA_GRID_LEARNING_PLATFORM: string = "Learning Platform";
    public static readonly ORGANIZATION_DATA_GRID_LEARNING_PLATFORM_HOST_URL: string = "Platform URL";
    public static readonly ORGANIZATION_DATA_GRID_INTEGRATION_TYPE: string = "Integration Type";
    public static readonly ORGANIZATION_DATA_GRID_STATUS: string = "Status";
    public static readonly ORGANIZATION_DATA_GRID_CREATE_DATE: string = "Create Date";
    public static readonly ORGANIZATION_DATA_GRID_SALESFORCE: string = "SalesForce ID";
    public static readonly ORGANIZATION_DATA_GRID_ENVIRONMENT: string = "Environment";

    public static readonly ORGANIZATION_DATA_GRID_BUNDLE_ID: string = "Bundle Id";
    public static readonly ADD_NEW_ORGANIZATION: string = "Create New Organization";
    public static readonly SSO_FREE_FORM_HEADER: string = "SSO Free form fields";
    public static readonly XAPI_FREE_FORM_HEADER_HEADER: string = "XAPI Free form header fields";
    public static readonly XAPI_FREE_FORM_BODY_HEADER: string = "XAPI Free form body fields";

    
    public static readonly  GENERAL_INFORMATION_HEADER : string = "Organization Details"
    public static readonly  DESCRIPTION_MAX_LETTERS_COUNT : string = "DESCRIPTION_MAX_LETTERS_COUNT"
    public static readonly  SSO_HEADER : string = "Sso Details"
    public static readonly  XAPI_HEADER : string = "XApi Details"
    public static readonly  BASIC_AUTHENTICATION_HEADER : string = "Authentication Details "
}